
import { dateFrancaise, sqlDate } from "@/plugins/variables"
import { DemandeMaintenance, Machine } from "@/store/types"
import Vue from "vue"
import axios from "axios"

export default Vue.extend({
  name: "Maintenance",
  data() {
    return {
      unsubscribe: "" as any,
      demandeMaintenances: [] as DemandeMaintenance[],
      demandeDeMaintenance: new DemandeMaintenance(),
      demandeDeMaintenanceTampon: new DemandeMaintenance(),
      machines: [] as any[],
      dialogueNoulleMaintenance: false,
      selectPriorite: [] as any[],
      selectEtatDemande: [] as any[],
      dictionnaire: [] as string[],
      snak_visible: false,
      snakbar_text: ""
    }
  },
  methods: {
    initialisation() {
      axios
        .post(this.$store.state.ajaxurl + "Maintenance.php", {
          action: "INITIALISER",
          ut_id: this.trouverUtilisateurNom(this.$store.state.utilisateur)
        })
        .then(response => {
          if (response.data.demandeMaintenances) {
            this.demandeMaintenances = response.data.demandeMaintenances.map((d: DemandeMaintenance) => {
              return new DemandeMaintenance(d)
            })
            this.machines = this.$store.state.machines.map((m: Machine) => {
              return { value: m.mac_id, text: m.mac_desi }
            })
          }
        })
    },
    ouvrirDialogueNouvelleMaintenance() {
      this.dialogueNoulleMaintenance = true
      this.demandeDeMaintenance = new DemandeMaintenance()
      this.demandeDeMaintenance.setDate(sqlDate(new Date()))
    },
    ouvrirModificationMaintenance(uneDemandeDeMaintenance: DemandeMaintenance) {
      this.demandeDeMaintenance = uneDemandeDeMaintenance
      this.demandeDeMaintenanceTampon = new DemandeMaintenance(uneDemandeDeMaintenance)
      this.dialogueNoulleMaintenance = true
    },
    enregistrerMaintenance() {
      if (this.demandeDeMaintenance.getIDMachine() != -1) {
        let modifier = true
        if (this.demandeDeMaintenance.getId() == "") {
          this.demandeDeMaintenance.setId(new Date().getTime() + "")
          this.demandeDeMaintenance.setUtilisateur(this.trouverUtilisateurNom(this.$store.state.utilisateur))
          modifier = false
        }
        if (this.demandeDeMaintenance.getPriorite() == "") {
          this.demandeDeMaintenance.setPriorite("F")
        }
        axios
          .post(this.$store.state.ajaxurl + "Maintenance.php", {
            action: "ENREGISTRER_MAINTENANCE",
            uneDemandeDeMaintenance: this.demandeDeMaintenance,
            modifier: modifier
          })
          .then(() => {
            this.fermerDialogueNouvelleMaintenance()
            this.initialisation()
          })
      } else {
        this.snackbarVisible(this.dictionnaire[9])
      }
    },
    fermerDialogueNouvelleMaintenance() {
      if (this.demandeDeMaintenanceTampon.getId() != "") {
        const index = this.demandeMaintenances.findIndex((d: DemandeMaintenance) => d.getId() == this.demandeDeMaintenanceTampon.getId())
        if (index != -1) {
          this.demandeMaintenances.splice(index, 1, this.demandeDeMaintenanceTampon)
        }
      }
      this.dialogueNoulleMaintenance = false
      this.demandeDeMaintenance = new DemandeMaintenance()
      this.demandeDeMaintenanceTampon = new DemandeMaintenance()
    },
    trouverUtilisateurNom(ut_nomPrenom: string) {
      const separation = ut_nomPrenom.split(" ")
      return this.$store.state.salaries.find((s: any) => s.ut_nom == separation[1] && s.ut_prenom == separation[0]).ut_id
    },
    retourneDateFrancaise(dte: string | Date) {
      return dateFrancaise(dte)
    },
    retournPrioriter(sdm_prioriter: string) {
      switch (sdm_prioriter) {
        case "F":
          return this.selectPriorite[0].text
        case "M":
          return this.selectPriorite[1].text
        case "E":
          return this.selectPriorite[2].text
        default:
          return "Error"
      }
    },
    retournEtatDemande(sdm_etatDemande: string) {
      switch (sdm_etatDemande) {
        case "D":
          return this.selectEtatDemande[0].text
        case "C":
          return this.selectEtatDemande[1].text
        case "T":
          return this.selectEtatDemande[2].text
        default:
          return "Error"
      }
    },
    choixlangue(lang: string) {
      switch (lang) {
        case "FR":
          this.dictionnaire = [
            "État de la demande", //0
            "Priorité", //1
            "Date", //2
            "Demande", //3
            "Demande de maintenance", //4
            "Vos demandes de maintenance", //5
            "Nouvelle demande de maintenance", //6
            "Enregistrer", //7
            "Machine", //8
            "Veuillez sélectionner une machine" ///9
          ]
          this.selectPriorite = [
            { text: "Faible", value: "F" },
            { text: "Moyen", value: "M" },
            { text: "Elever", value: "E" }
          ]
          this.selectEtatDemande = [
            { text: "Déclarée", value: "D" },
            { text: "En cours", value: "C" },
            { text: "Terminé", value: "T" }
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Stand der Nachfrage", //0
            "Priorität", //1
            "Datum", //2
            "Antrag", //3
            "Antrag auf Wartung", //4
            "Ihre Anfragen für die Wartung", //5
            "Neue Anfrage für die Wartung", //6
            "Speichern", //7
            "Maschine", //8
            "Bitte wählen Sie eine Maschine" //9
          ]
          this.selectPriorite = [
            { text: "Niedrige", value: "F" },
            { text: "Mittlere", value: "M" },
            { text: "Hohe", value: "E" }
          ]
          this.selectEtatDemande = [
            { text: "Erstellt", value: "D" },
            { text: "In Bearbeitung", value: "C" },
            { text: "Abgeschlossen", value: "T" }
          ]
          break
      }
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    }
  },
  created() {
    this.initialisation()
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixlangue(this.$store.state.lang)
          break
      }
    })
    this.choixlangue(this.$store.state.lang)
  },
  beforeDestroy() {
    this.unsubscribe = []
  }
})
